@import "../../../../assets/theme/_var.scss";

.createAlert {
    .mb-4 {
        margin-bottom: 2rem !important;
    }

    label {
        color: $labeltextclr;
        font-size: 1.4rem;
        font-weight: 400;

        @include theme() {
            color: theme-get("labeltextclr");
        }

        @media (max-width:991px) {
            margin-bottom: 1rem;
        }
    }

    p {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: normal;
        color: $labeltextclr;

        @include theme() {
            color: theme-get("labeltextclr");
        }
    }

    .triggerTabs {
        background-color: $greybg;
        border-radius: 0.8rem;
        margin-bottom: 1.2rem;

        @include theme() {
            background-color: theme-get("greybg");
        }

        .btn-style {
            border-radius: 0.8rem;
            min-height: 3.2rem;
            width: 50%;
            background-color: transparent;
            font-size: 1.4rem;
            padding: 0;

            @include theme() {
                color: theme-get("textcolor");
            }

            &.active {
                background-color: $greenbg;

                @include theme() {
                    color: theme-get("whitebtn");
                }
            }
        }
    }

    .alertNotification {
        border-top: 1px solid $white20;
        padding-top: 3rem;
        margin-top: 1rem;

        @include theme() {
            border-color: theme-get("whiteborder01");
        }

        ul {
            li {
                margin-right: 3rem;
                max-width: 24rem;
                width: 100%;

                @media (max-width:767px) {
                    margin-bottom: 1.5rem;
                    max-width: 100%;
                }

                &:last-child {
                    margin-right: 0;
                }

                p {
                    margin-top: 0.8rem;
                }

                .checkbox_input {
                    margin-bottom: 0;

                    .form-check {
                        .form-check-label {
                            margin-bottom: 0;
                            font-weight: 600;
                            color: $white;

                            @include theme() {
                                color: theme-get("textcolor");
                            }
                        }
                    }
                }
            }
        }
    }

    .alertBtns {
        justify-content: flex-end;

        .btn-style {
            min-width: 11.6rem;
        }
    }
}

.AlertForm {
    .common_select {
        .innerSelect {
            padding: 0;

            .select__control {
                padding: 0.5rem 1.2rem;
                min-height: 3.6rem;

                .select__indicators {
                    .select__indicator {
                        position: static;
                    }
                }
            }
        }
    }

    .customInput {
        min-height: 3.6rem;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        &_inner {
            width: 100%;
        }

        .upDownarrow {
            span {
                height: 1.5rem;
            }
        }
    }
}