@import '../../../assets/theme/_var.scss';

.common_select {

  .innerSelect {
    background-color: $greybg;
    border-radius: 0.8rem;
    padding: 0.6rem 1.2rem;
    position: relative;

    @include theme() {
      background-color: theme-get("greybg");
    }

    label {
      display: block;
      color: $labeltextclr;
      font-size: 1rem;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.2rem;

      @include theme() {
        color: theme-get("labeltextclr");
      }
    }
  }

  .select__control {
    background-color: $greybg;
    cursor: pointer;
    border: none;
    font-size: 1.2rem;
    border-radius: 0.8rem;
    transition: none;
    box-shadow: none;
    border: none;
    min-height: auto;

    @include theme() {
      background-color: theme-get("greybg");
    }

    .select__input-container {
      color: $white;
      margin: 0;
      padding: 0;
      opacity: 1;

      .select__input {
        opacity: 0 !important;
      }
    }

    .select__placeholder {
      color: $white;
      margin: 0;
      font-weight: 500;
      opacity: 1;

      @include theme() {
        color: theme-get("textcolor");
      }
    }

    .select__value-container {
      padding: 0;

      .select__multi-value {
        background-color: $baseColor;
        color: $white;

        @include theme() {
          background-color: theme-get("baseColor");
          color: theme-get("textcolor");
        }

        .select__multi-value__label {
          color: $white;

          @include theme() {
            color: theme-get("textcolor");
          }
        }
      }

      .select__single-value {
        color: $white;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0;
        font-weight: 500;

        @include theme() {
          color: theme-get("textcolor");
        }
      }
    }

    .select__indicators {
      .select__indicator {
        padding: 0;
        position: absolute;
        right: -4px;
        top: -8px;

        svg {
          fill: $white;
          transition: all ease-in-out 0.3s;

          @include theme() {
            fill: theme-get("textcolor");
          }
        }
      }
    }

    &--menu-is-open {
      .select__indicator {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .select__indicator-separator {
      display: none;
    }


  }

  .select__menu {
    width: 100%;
    right: 0;
    left: unset;
    background-color: $greybg;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 2;
    border-radius: 0.8rem;

    @include theme() {
      background-color: theme-get("greybg");
    }

    .select__menu-notice {
      font-size: 1.2rem;
    }

    .select__menu-list {
      padding: 0;

      .select__option {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        color: $white;

        @include theme() {
          color: theme-get("textcolor");
        }

        &:first-child {
          border-radius: 0.8rem 0.8rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.8rem 0.8rem;
        }

        &.select__option--is-selected {
          background-color: $blueclr !important;
          color: $white;

          &:hover {
            background-color: $blueclr;
            color: $white;
          }
        }

        &.select__option--is-focused {
          background-color: transparent;
        }

        &:hover {
          color: $blueclr;
        }
      }
    }
  }
}