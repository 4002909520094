@import '../../../assets/theme/_var.scss';

.btn-style,
.btn-primary {
  min-height: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $baseFont;
  border-radius: 0.8rem;
  padding: 0.6rem 2.5rem;
  font-size: 1.6rem;
  font-weight: 500;
  background: $blueclr;
  border: 0;
  text-transform: capitalize;
  transition: all ease-in-out 0.3s;
  color: $white;

  @media (max-width: 1199px) {
    font-size: 1.4rem;
  }

  &:hover {
    background: $btnbghover;
    color: $white;
  }

  &.transparent {
    background: transparent;
    border: none;
  }

  &.btn-sm {
    min-height: 2rem;
    font-size: 1.2rem;
    min-width: auto;
    padding: 0.5rem 1rem;
    line-height: normal;
    border-radius: 0.5rem;
  }

  &.white-btn {
    background: $white;
    color: $blueclr;
    border: 1px solid transparent;

    @include theme() {
      background: theme-get("whitebg");
      border-color: theme-get("blueborder");
    }

    &:hover {
      background: $blueclr;
      color: $white;
    }
  }

  &.black-btn {
    background: $black;
    color: $white;

    &:hover {
      background: $blueclr;
      color: $white;
    }
  }

  &.greendark-btn {
    background: $greenbg;
    color: $white;

    &:hover {
      background: $greenclr;
      color: $white;
    }
  }

  .onlyIcon {
    margin-right: 0.7rem;
    display: inline-flex;

    svg {
      path {
        fill: $white;
      }

      &:hover {
        path {
          fill: currentColor;
        }
      }
    }
  }

  &.border-btn {
    border-radius: 1.8rem;
    border: 1px solid $borderColor;
    background: rgba(224, 224, 255, 0.02);
    padding: 1rem 2rem;
    min-width: 14rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: 0.4px;
    color: $white;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s;

    &:hover {
      background-color: $blueclr;
      border-color: $blueclr;
      color: $white;
    }
  }

  .rightIcon {
    margin-left: 1rem;

    svg {
      path {
        fill: $white;
      }

      &:hover {
        path {
          fill: currentColor;
        }
      }
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}