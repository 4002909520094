@import "../../../assets/theme/_var.scss";

.tableless {
    border-radius: 1rem;
    height: 100%;

    .commonTable {
        tr {

            th,
            td {
                white-space: nowrap;
                vertical-align: middle;
                background-color: transparent;
                font-family: $baseArialFont;

                .clickIcon {
                    cursor: pointer;
                }
            }
        }

        thead {
            border: none;
            position: sticky;
            top: 0;
            background-color: $cardbg;

            @include theme() {
                background-color: theme-get("cardbg");
            }

            z-index: 2;

            tr {
                th {
                    border-right: none;
                    border-left: none;
                    font-size: 1.1rem;
                    font-weight: 500;
                    color: $textclr1;
                    border-bottom: none;
                    padding: 1rem 1.2rem;

                    @include theme() {
                        color: theme-get("tabletext");
                    }

                    @media (max-width:1399px) {
                        padding: 1rem 0.5rem;
                    }

                    .sort_icon {
                        svg {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
            }
        }

        tbody {

            tr {
                td {
                    font-size: 1.1rem;
                    font-weight: 400;
                    border-bottom: 0;
                    color: $white;
                    padding: 0.8rem 1.2rem;

                    @include theme() {
                        color: theme-get("tabletext");
                    }

                    @media (max-width:1399px) {
                        padding: 0.8rem 0.5rem;
                    }

                    a {
                        color: $blueclr;
                    }

                    p {
                        font-size: 1.1rem;
                        font-weight: 400;
                    }

                    .yellowclrIcon {
                        svg {
                            path {
                                fill: $yellowclr;
                            }
                        }
                    }

                    .redclrIcon {
                        svg {
                            path {
                                fill: $redclr;
                            }
                        }
                    }

                }
            }

            tr {
                &.no_record {
                    td {
                        padding: 1.5rem 0;

                        .no_record_found {
                            text-align: center;
                        }
                    }
                }
            }
        }

        &.innerCommontable {
            --bs-table-bg: transparent !important;

            thead {
                border: none;

                @include theme() {
                    background-color: transparent;
                }

                tr {

                    th {
                        border: 0;
                        color: $white;
                        padding: 1.4rem 1.6rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                        letter-spacing: 0.6px;
                        text-transform: capitalize;
                        background-color: $borderColor;

                        @include theme() {
                            background-color: theme-get("greybg");
                            color: theme-get("tabletext");
                        }

                        @media (max-width: 767px) {
                            padding: 1.4rem 1rem;
                        }


                        &:first-child {
                            border-radius: 1rem 0 0 1rem;
                        }

                        &:last-child {
                            border-radius: 0 1rem 1rem 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 1px solid $whiteborder01;

                    @include theme() {
                        border-color: theme-get("whiteborder01");
                    }
                }

                td {
                    padding: 1.6rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.5rem;
                    letter-spacing: 0px;
                    border-bottom: 0;
                    color: $white;

                    @include theme() {
                        color: theme-get("tabletext");
                    }

                    @media (max-width: 767px) {
                        padding: 1.4rem 1rem;
                    }

                    a {
                        color: $white;

                        @include theme() {
                            color: theme-get("tabletext");
                        }
                    }

                    .copyIcon {
                        cursor: pointer;
                    }

                    &.tableDowpdown {
                        .dropdown {
                            .dropdown-toggle {
                                &::after {
                                    display: none;
                                }
                            }

                            .dropdown-menu {
                                border-radius: 5px;
                                box-shadow: 0px 0px 10px 0px rgba(0, 133, 255, 0.25);

                                a {
                                    color: $white;
                                    font-size: 1.4rem;
                                    font-weight: 600;
                                    line-height: normal;
                                    text-transform: capitalize;

                                    &:hover {
                                        color: $textclr1;
                                    }
                                }
                            }
                        }
                    }
                }

                tr {
                    &.no_record {
                        td {
                            padding: 1.5rem 0;

                            .no_record_box {
                                padding: 5rem 1.5rem;

                                svg {
                                    opacity: 1;

                                    path {
                                        fill: $white;
                                    }
                                }

                                h4 {
                                    font-size: 1.6rem;
                                    font-weight: 600;
                                    margin-top: 1rem;
                                    color: $white;

                                    @media (max-width: 1679px) {
                                        font-size: 1.4rem;
                                    }
                                }

                                @media (max-width: 991px) {
                                    padding: 4rem 2rem;
                                }
                            }
                        }
                    }
                }
            }

            .tableBtn {
                min-height: 2.5rem;
                min-width: 6.8rem;
                border-radius: 10rem;
                padding: 0.6rem 0.8rem;
                font-size: 1.2rem;
                font-weight: 500;
                border: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: $blueclr;
                color: $white;
                border: 1px solid transparent;

                &.white-btn {
                    background-color: $white;
                    color: $blueclr;

                    @include theme() {
                        background-color: theme-get("whitebtn");
                        color: theme-get("bluetextclr");
                        border-color: theme-get("bluetext");
                    }

                    &:disabled {
                        color: #848E9C;

                        &:hover {
                            background-color: $white;
                        }
                    }
                }

                &.greenBG {
                    background: $lightgreen;
                    color: $green;

                    &:hover {
                        background: $green;
                        color: $white;
                    }
                }

                &.redBG {
                    background: $lightred;
                    color: $red;

                    &:hover {
                        background: $red;
                        color: $white;
                    }
                }

                &.blueBG {
                    background: $bluebg;
                    color: $white;

                    &:hover {
                        background: $whitebg;
                        color: $bluetext;
                    }
                }

                &.yellowBG {
                    background: $yellowlightbg;
                    color: $glodclr;

                    // &:hover {
                    //     background: $whitebg;
                    //     color: $bluetext;
                    // }
                }



                svg {
                    margin-right: 0.5rem;
                }
            }

        }
    }
}


.no_record_box {
    padding: 5rem 1.5rem;
    text-align: center;

    svg {
        opacity: 1;

        path {
            fill: $white;
        }
    }

    img {
        width: 10rem !important;
    }

    h4 {
        font-size: 1.3rem;
        font-weight: 400;
        margin-top: 1rem;
        color: $textclr1;
        font-family: $baseArialFont;
    }

    @media (max-width: 991px) {
        padding: 4rem 2rem;
    }
}