@import '../../../assets/theme/_var.scss';

.form-label,
.form-check-label {
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.8rem;

    @include theme() {
        color: theme-get("textcolor");
    }
}

.customInput {
    margin-bottom: 1rem;
    line-height: normal;
    padding: 0.6rem 1.2rem;
    border-radius: 0.8rem;
    border: 0;
    background-color: $greybg;
    position: relative;

    @include theme() {
        background-color: theme-get("greybg");
        // border-color: theme-get("whiteborder01");
    }

    .upDownarrow {
        position: absolute;
        right: 0.7rem;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;

        span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            align-items: center;

            svg {
                path {
                    @include theme() {
                        stroke: theme-get("textcolor");
                    }
                }
            }
        }
    }

    label {
        display: block;
        color: $labeltextclr;
        font-size: 1rem;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.2rem;

        @include theme() {
            color: theme-get("labeltextclr");
        }

        sup {
            color: #E74A54;
        }
    }

    .form-control {
        box-shadow: none;
        outline: none;
        width: 100%;
        padding: 0;
        border: 1px solid transparent;
        background-color: $greybg;
        color: $white;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: normal;
        border-radius: 0;

        @include theme() {
            background-color: theme-get("greybg");
            color: theme-get("textcolor");
        }

        &:hover {
            appearance: none;
        }

        &::placeholder {
            color: $white;

            @include theme() {
                color: theme-get("labeltextclr");
            }
        }

        &:focus {
            box-shadow: none;
        }

        &.passwordInput {
            padding-right: 7rem;
        }
    }

    &.passwordInput {
        .form-control {
            padding-right: 6rem;
        }
    }

    .eyeIcon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;

        svg {
            // width: 1.6rem;
            // height: 1.6rem;

            path {
                fill: #505050;
            }
        }
    }
}

.checkbox_input {
    .form-check {
        margin-bottom: 0;
        padding: 0;
        display: flex;
        align-items: center;

        .form-check-input {
            float: unset;
            margin: 0;
            box-shadow: none;
            width: 20px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid $borderClr8891A3;
            background-color: $white;

            &:checked {
                background-color: $white;

                &[type="checkbox"] {
                    background-image: url("../../../assets/images/check.svg");
                }
            }

            
        }       

        .form-check-label {
            line-height: 1;
            margin-left: 10px;
        }

        &.form-switch {
            .form-check-input {
                width: 2.7rem;
                height: 1.6rem;
                background-color: $inputlabelclr;
                border-color: $inputlabelclr;
                background-image: url("../../../assets/icons/whitedot.svg");
                background-size: 1.4rem;

                &:focus {
                    background-color: $inputlabelclr;
                    border-color: $inputlabelclr;
                    background-image: url("../../../assets/icons/whitedot.svg");
                }

                &:checked {
                    background-color: $greenclr;
                    border-color: $greenclr;
                    background-image: url("../../../assets/icons/whitedot.svg");
                }


            }
        }
    }
}

.error-message {
    color: $redclr;
    font-size: 1.4rem;
    line-height: normal;
    display: block;
    padding-top: 0.5rem;
}