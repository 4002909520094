@import "../Fonts/Fonts.scss";

$baseFont: "Arbeit Pro Trial", sans-serif;
$baseArialFont: 'Arial', sans-serif;
$baseColor: #101315;
$blueclr: #1469E7;
$blueclr1: #438FFF;
$textclr: #6D7A8C;
$textclr1: #848E9C;
$textclr2: #71778B;
$textclr3: #8692A6;
$greytextclr: #B7BDC6;
$labeltextclr: #94A4BA;
$white: #ffffff;
$white50: rgba(255, 255, 255, 0.50);
$white20: rgba(255, 255, 255, 0.20);
$black: #000000;
$borderColor: #2B3139;
$borderColor1: #EDF7FF;
$borderClr8891A3: #8891A3;
$whiteborder01: rgba(255, 255, 255, 0.1);
$inputborder: #BEBEBE;
$dropdwonBg: #1F2127;
$btnbghover: #1154b9;
$cardbg: #161A1E;
$greybg: #2B3139;
$greydarkbg: #181A1F;
$inputlabelclr: #60636d;
$inputlabelclr1: #505050;
$placeholderclr: #6b96d4;
$blackplaceholderclr: #222222;
$yellowclr: #E8922F;
$redclr: #F54545;
$greenclr: #0ECB81;
$greenbg: #0ECB81;
$greentextclr: #41C39C;
$scrollclr: #828488;
$loader: 1100;
$neutralclr10: #1C1C1C;
$modalbgclr: #131623;
$text989898: #989898;
$lightgreen: #cdffcd;
$lightred: #ffe0e0;
$green: #007f00;
$red: #d30000;
$bluetext: #4a4aff;
$bluebg: #00A3FF;
$whitebg: #e6e6f2;
$tableborder: rgba(#f5f5f5, 0.2);
$yellowlightbg: #FFF8E1;
$glodclr: #896500;

$themes: (
    light-theme: ("textcolor": #161A1E,
        "baseColor": #ffffff,
        "cardbg": #ffffff,
        "dropbg": #ffffff,
        "blueborder": #438FFF,
        "bluetext": #4A4AFF,        
        "bluetextclr": #4A4AFF,        
        "whiteborder01": rgba(32, 32, 32, 0.1),
        "boxshadow": rgba(67, 143, 255, 0.2),
        "greybg": #F1F5F9,
        "tabbg": #ffffff,
        "lighttextclr": rgb(138, 138, 138),     
        "tabletext": #31373E,
        "whitebtn": #ffffff, 
        "labeltextclr":#31373E,
        "greydarkbg": #F1F5F9,
        "charttextclr": #161A1E,
        "workspacebg":#F1F5F9,
    ),
 
    dark-theme: ("textcolor": #ffffff,
        "baseColor":#101315,
        "cardbg": #161A1E,
        "dropbg": #161A1E,
        "blueborder": #ffffff,
        "bluetext": #ffffff,
        "bluetextclr": #4A4AFF,
        "whiteborder01": rgba(255, 255, 255, 0.1),
        "boxshadow": rgba(67, 143, 255, 0),
        "greybg": #2B3139,
        "lighttextclr": rgba(255, 255, 255, 0.50),       
        "tabletext": #ffffff, 
        "whitebtn": #ffffff, 
        "tabbg": #2B3139,
        "labeltextclr":#94A4BA ,
        "greydarkbg": #181A1F,
        "charttextclr": #989898,
        "workspacebg":#101315,
    ),
);

/* ===== theme ===== */
@mixin theme() {

    @each $theme,
    $map in $themes {
        $theme-map: $map !global;

        .#{$theme} & {
            @content;
        }
    }

    $theme-map: null !global;
}

@function theme-get($key) {
    @return map-get($theme-map, $key);
}

// @include theme() {
//   color: theme-get("textGreen");
// }