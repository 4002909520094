@import "../../../assets/theme/_var.scss";

.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $loader;
    background-color: rgba($neutralclr10, 0.4);
    backdrop-filter: blur(1rem);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 10rem;
        max-height: 20rem;
        object-fit: cover;
        object-position: center;

        :global {
            @keyframes blink {
                from {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }
        }

        @media (max-width: 767px) {
            width: 7rem;
            max-height: 8rem;
        }

        &.blink {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            animation: blink 1.4s ease-in-out infinite alternate;
        }
    }
}