@import '../../../assets/theme/_var.scss';

.siteHeader {
  padding: 0rem 2rem;
  min-height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: $greydarkbg;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;

  @include theme() {
    background-color: theme-get("greydarkbg");
    box-shadow: 0 0 1.2rem 0.5rem theme-get("boxshadow");
  }

  @media (max-width: 1199px) {
    padding: 1rem 1.5rem;
    z-index: 9999;
  }

  .navbar {
    padding: 0;
    width: 100%;

    .brandLogo {
      margin-right: 3.2rem;

      @media (max-width: 1199px) {
        margin-right: 0rem;
        max-width: 15rem;
      }

      img {
        max-width: 18rem;

        @media (max-width: 1199px) {
          max-width: 15rem;
        }
      }
    }

    &-collapse {
      @media (min-width: 1200px) {
        height: auto !important;
      }

      .nav-link {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.4rem;
        margin: 0 0.2rem;
        padding: 0 0.9rem;
        background-color: transparent;
        border-bottom: 3px solid transparent;
        min-height: 6.4rem;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @include theme() {
          color: theme-get("textcolor");
        }

        &:hover,
        &.active {
          color: $blueclr;
          border-color: $blueclr;
        }

        @media (max-width: 1199px) {
          margin: 1rem 0rem;
          padding: 1rem 1.2rem;
          min-height: auto;
          border: 0;

          &.active {
            color: $white;
            background-color: $blueclr;
          }
        }
      }

      @media (max-width: 1199px) {
        position: fixed;
        left: -29rem;
        top: 0rem;
        background-color: $greydarkbg;
        width: 29rem;
        padding: 2rem 1rem;
        display: block;
        transition: all ease-in-out 0.3s;
        height: calc(100vh - 0px);
        z-index: 9999;
        overflow-y: auto;

        @include theme() {
          background-color: theme-get("greydarkbg");
        }

        a {
          display: flex;
          justify-content: flex-start;
          text-align: left;
        }

        &.show {
          left: 0;
        }

        .brandLogo {
          padding-bottom: 3rem;
        }
      }
    }

    .navDropdown {
      &.dropdown {
        .dropdown-toggle {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.4rem;
          margin: 0 0.2rem;
          padding: 0 0.9rem;
          background-color: transparent;
          border-bottom: 3px solid transparent;
          min-height: 6.4rem;
          display: flex;
          align-items: center;
          white-space: nowrap;
          border-radius: 0;

          @include theme() {
            color: theme-get("textcolor");
          }

          svg {
            margin-left: 1rem;
            transition: all ease-in-out 0.3s;

            path {
              @include theme() {
                stroke: theme-get("textcolor");
              }
            }
          }

          &:hover,
          &.show {
            color: $blueclr;
            border-color: $blueclr;

            svg {
              transform: rotate(180deg);

              path {
                stroke: $blueclr;
              }
            }
          }

          &::after {
            display: none;
          }

          @media (max-width: 1199px) {
            margin: 1rem 0rem 0;
            padding: 1rem 1.2rem;
            min-height: auto;
            border: 0;
            width: 100%;
            justify-content: space-between;
  
            &.show {
              color: $white;
              background-color: $blueclr;

              svg {  
                path {
                  @include theme() {
                    stroke: theme-get("textcolor");
                  }
                }
              }
            }
          }

        }

        .dropdown-menu {
          width: 25.5rem;

          @media (max-width: 1199px) {
            position: static;
            width: 100%;
            border-radius: 0;
          }

          a {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.4rem;
          }

          p {
            padding: 1rem 1.2rem;
            border-top: 1px solid;
            margin-top: 0.5rem;

            @include theme() {
              color: theme-get("lighttextclr");
              border-color: theme-get("whiteborder01");
            }
          }
        }
      }
    }

    .navbar-toggler {
      background-color: transparent;
      margin-left: 2rem;
      padding: 0;
      position: relative;
      width: 3rem;
      height: 2rem;
      border: 0;

      &:focus {
        box-shadow: none;
      }

      @media (max-width: 767px) {
        margin-left: 1rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3rem;
        background-color: $white;
        height: 2px;
        transition: all ease-in-out 0.3s;

        @include theme() {
          background-color: theme-get("textcolor");
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        background-color: $white;
        height: 2px;
        transition: all ease-in-out 0.3s;

        @include theme() {
          background-color: theme-get("textcolor");
        }
      }

      .navbar-toggler-icon {
        background-image: none;
        height: 2px;
        background-color: $blueclr;
        width: 2.2rem;
        transition: all ease-in-out 0.3s;
        display: flex;
      }
    }
  }

  &.openmenu {
    .navbar {
      .navbar-toggler {
        &::after {
          transform: rotate(45deg) translate(-6px, -6px);
          background-color: $blueclr;
        }

        &::before {
          transform: rotate(-45deg) translate(-6px, 7px);
          background-color: $blueclr;
        }

        .navbar-toggler-icon {
          opacity: 0;
        }
      }
    }
  }

  .rightHeader {

    .dropdown {
      .dropdown-toggle {
        padding: 0;

        &::after {
          display: none;
        }

        display: inline-block;
        // width: 5rem;

        svg {
          transition: all ease-in-out 0.3s;

          path {
            @include theme() {
              stroke: theme-get("textcolor");
              // stroke: $blueclr;
              fillOpacity: 1;
            }
          }
        }

        &.show {
          svg {
            path {
              stroke: $blueclr;
              fillOpacity: 1;
            }
          }
        }
      }

      .dropdown-menu {
        min-width: 20rem;

        @media (max-width: 575px) {
          min-width: 22rem;
        }

        a {
          border-radius: 0;
          padding: 0.7rem 1.2rem;

          &:hover {
            background-color: transparent;
            color: $blueclr;
          }
        }
      }
    }

    .userDropdown {
      margin: 0 2rem;

      @media (max-width: 991px) {
        margin: 0rem;
        margin-right: 1rem;
      }
    }

    .NotificationDropdown {
      .dropdown {
        .dropdown-menu {
          min-width: 20rem;
          padding: 1rem;

          @media (max-width: 575px) {
            min-width: 22rem;
          }

          a {
            border-radius: 0;
            padding: 0.7rem 1.2rem;
            font-family: $baseArialFont;

            svg {
              width: 1.7rem;
              height: 1.7rem;

              path {
                fill: $white;
                stroke: none;

                @include theme() {
                  fill: theme-get("textcolor");
                }
              }
            }

            &:hover,
            &.active,
            &:focus {
              background-color: transparent;
              color: $blueclr;

              svg {
                path {
                  fill: $blueclr;
                }
              }
            }
          }
        }
      }
    }
  }

  .headerFunds {
    @media (max-width: 1199px) {
      margin-top: 2rem;
    }

    ul {
      li {
        padding-right: 2.4rem;

        @media (max-width: 1399px) {
          padding-right: 1.5rem;
        }

        @media (max-width: 1199px) {
          padding: 0;
          padding-bottom: 1rem;
        }

        h6 {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 4px;

          @include theme() {
            color: theme-get("textcolor");
          }

          &:last-child {
            font-weight: 500;
          }
        }
      }
    }
  }

  .coinDropdown {
    .customDropdown {
      min-width: 18rem;
    }
  }

  .theme_btn {
    border: 1px solid $borderColor;
    background-color: rgba($borderColor1, 0.14);
    width: 4rem;
    height: 2.5rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;

    @include theme() {
      background: theme-get("greybg");
      border-color: theme-get("whiteborder01");
    }

    &.active {
      justify-content: flex-end;

      >div {
        background: linear-gradient(288.42deg, #24214E -18.16%, #050520 100.37%);

        svg {
          transform: translateY(-0.1rem);
        }
      }
    }

    >div {
      flex-shrink: 0;
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 50%;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
      background: $white;
      display: flex;
      align-items: center;
      justify-content: center;

      @include theme() {
        background: theme-get("baseColor");
      }
    }
  }
}

.sidebar_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(4px);
  z-index: 1000;
  background-color: #000;
  opacity: 0.4;
}

.layoutResetbtn {
  background-color: $blueclr;
  color: $white;
  transition: all ease-in-out 0.2s;
  border-radius: 1rem;
  border: 0;
  padding: 1rem 2rem;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.4rem;

  &:hover {
    background-color: $btnbghover;
    color: $white;
  }
}

.Notification_Dropdown {

  .Show_Notification_No {
    width: 14px;
    height: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $blueclr;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: $white;
    border-radius: 100px;
    position: absolute;
    top: -4px;
    right: -3px;

    @include theme() {
      color: theme-get("textcolor");
    }
  }

  .dropdown-menu {
    width: 300px;
    top: 4rem;
    padding: 0px 0px;
    border-radius: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.38);
    border: 0;
    padding-bottom: 1rem;

    @include theme() {
      background: theme-get("cardbg");
    }

    @media (max-width:767px) {
      right: -40px;
    }

    ul {
      max-height: 33.5rem;
      overflow-y: auto;
      margin-bottom: 1rem;

      li {
        padding: 1.5rem;
        cursor: pointer;
        margin-bottom: 1px;
        transition: all ease-in-out 0.3s;
        background-color: $cardbg;

        @include theme() {
          background: theme-get("cardbg");
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover,
        &.active {
          background-color: $greybg;

          @include theme() {
            background: theme-get("greybg");
          }
        }

        span {
          transition: all ease-in-out 0.3s;
        }

        h5 {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: $white;
          transition: all ease-in-out 0.3s;

          @include theme() {
            color: theme-get("textcolor");
          }

          small {
            display: block;
            font-weight: 300;
            font-size: 1.1rem;
            line-height: 1.4rem;
            color: $white;
            margin-top: 0.6rem;

            @include theme() {
              color: theme-get("textcolor");
            }

          }
        }

        p {
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: $white;
          text-align: right;
          margin-top: 0.5rem;

          @include theme() {
            color: theme-get("textcolor");
          }

        }
      }
    }
  }


  .loadMorebtn {
    padding: 0 1.5rem;

    .btn-style {
      background-color: $greybg;
      font-size: 1.4rem;

      &:hover {
        background-color: $blueclr;
      }
    }
  }
}