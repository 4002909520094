@import "../../../assets/theme/_var.scss";

.commonSearch {
    .searchInput {
        position: relative;

        .searchIcon {
            position: absolute;
            top: 47%;
            transform: translateY(-50%);
            left: 1rem;

            svg { 
                path {
                    fill: #5E6673;
                }
            }
        }

        input {
            background-color: $greybg;
            border: 0;
            border-radius: 0.4rem;
            padding: 0.5rem 1rem 0.5rem 3rem;
            color: $white;
            font-size: 1.2rem;
            font-weight: 400;
            font-family: $baseArialFont;
            line-height: normal;
            min-height: 2.8rem;
            width: 100%;

            @include theme() {
                background-color: theme-get("greybg");
                color: theme-get("textcolor");
            }

            &:focus {
                box-shadow: none;
                outline: 0;
            }

            &::placeholder {
                color: #5E6673;

                @include theme() {
                    color: theme-get("tabletext");
                }
            }
        }
    }
}