@import "../../../assets/theme/_var.scss";

.customPagination {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
        color: #6E6893;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.6px;
    }

    .pagination {
        justify-content: flex-end;

        .lineclr {
            color: #6E6893;
        }

        li {
            .page-link {
                background-color: transparent;
                border: 0;
                color: #6E6893;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.6px;
                padding: 0.4rem 1.2rem;

                // @include theme() {
                //     color: theme-get("textcolor");
                // }

                &:hover {
                    color: $white;

                    @include theme() {
                        color: theme-get("bluetext");
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }

            &.active {
                .page-link {
                    color: $white;

                    @include theme() {
                        color: theme-get("bluetext");
                    }
                }
            }
        }

        li {

            &.prevArrow,
            &.nextArrow {
                a {
                    font-size: 2.2rem;
                    line-height: 2.4rem;
                    padding: 0;

                }
            }
        }

        li {
            &.prevArrow {
                margin-right: 1.5rem;
            }

            &.nextArrow {
                margin-left: 1.5rem;
            }
        }
    }

    // .common_select {
    //     .innerSelect {
    //         padding: 0 4px;
    //         min-width: 6rem;
    //         background-color: transparent;

    //         .select__control {
    //             background-color: transparent;

    //             .select__value-container {
    //                 .select__single-value {
    //                     color: #6E6893;
    //                     font-size: 1.4rem;
    //                     font-weight: 500;
    //                 }
    //             }

    //             .select__indicators {
    //                 .select__indicator {
    //                     right: 0;
    //                     top: 0;

    //                     svg {
    //                         fill: #6E6893;
    //                     }
    //                 }
    //             }
    //         }

    //         .select__menu {
    //             .select__menu-list {
    //                 .select__option {
    //                     padding: 3px 5px;
    //                 }
    //             }
    //         }
    //     }
    // }
}