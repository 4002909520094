@import "../../../assets/theme/_var.scss";

.settingsPage {
    padding: 4rem 0;

    .borderTabs {
        &.nav {
            border: 0;

            .nav-item {
                margin-right: 3rem;

                &:last-child {
                    margin-right: 0;
                }

                .nav-link {
                    border-bottom: 2px solid transparent;
                    padding: 0rem 0rem 1rem;
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: $white;

                    &.active {
                        border-color: $blueclr;
                        color: $blueclr;

                        svg {
                            path {
                                fill: $blueclr;
                            }
                        }
                    }
                }
            }
        }
    }

    .activeAlerts {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 10rem;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        color: $white;
        margin-left: 1rem;
    }
}