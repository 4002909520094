@import "../../../../assets/theme/_var.scss";

.expiredAlertsTable {
    .table-responsive {
        max-height: 40rem;
        overflow-y: auto;

        @media (max-width:1149px) {
            max-height: 33.5rem;
            margin-top: 1.2rem;
        }

        @media (max-width:767px) {
            max-height: 22.5rem;
        }
    }
}