@import "../../../assets/theme/_var.scss";

.customDropdown {
    display: flex;
    align-items: center;
    border-radius: 0.8rem;
    // background-color: $greydarkbg;
    padding: 0;
    position: relative;

    @include theme() {
        background-color: theme-get("dropbg");
    }


    .customIcon {
        margin-left: 1rem;
    }

    .Dropdown {
        &-root {
            position: static;
            width: 100%;
        }

        &-control {
            padding: 0.6rem 1.2rem;
            background-color: transparent;
            border: 1px solid $borderColor1;
            color: #ffffff;
            font-size: 1.4rem;
            font-weight: 500;
            border-radius: 0.8rem;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 40px;

            @include theme() {
                border-color: theme-get("textcolor");
                color: theme-get("textcolor");
            }
        }

        &-placeholder {
            font-weight: 600;
            padding-right: 0.8rem;
        }

        &-arrow-wrapper {
            .Dropdown-arrow {
                top: 1.7rem;
                right: 1.3rem;
                background-image: url("../../../assets/icons/downarrow.svg");
                width: 1.2rem;
                height: 0.8rem;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1.2rem;
                border: none;
                transition: all ease-in-out 0.3s;               
            }
        }

        &-menu {
            left: 0;
            border-radius: 0.8rem;
            background-color: $greybg;
            border: 0;
            margin-top: 0rem;
            position: absolute;
            width: 100%;

            @include theme() {
                background-color: theme-get("cardbg");
            }

            .Dropdown-option {
                color: $textclr;
                transition: all ease-in-out 0.3s;
                padding: 0.5rem 1.2rem;
                font-size: 1.4rem;
                background-color: transparent;
                position: relative;
                font-weight: 500;
                color: $white;
                cursor: pointer;

                @include theme() {
                    color: theme-get("textcolor");
                }

                &:first-child {
                    border-radius: 0.8rem 0.8rem 0 0;
                }

                &:last-child {
                    border-radius: 0 0 0.8rem 0.8rem;
                }

                &:focus,
                &.is-selected {
                    color: $white;
                    background-color: $blueclr;

                    &:hover {
                        color: $white;
                        background-color: $blueclr;
                    }
                }

                &:hover {
                    color: $blueclr;
                    // background-color: $baseColor;
                }
            }
        }
    }

    .is-open {
        .Dropdown-arrow-wrapper {
            .Dropdown-arrow {
                rotate: (-180deg);
            }
        }
    }
}

.light-theme {
    .customDropdown {
        .Dropdown-control {
            .Dropdown-arrow-wrapper {
                .Dropdown-arrow {
                    filter: brightness(1) invert(1);
                }
            }
        }
    }
}