@font-face {
    font-family: 'Arbeit Pro Trial Book';
    src: url('ArbeitProTrial-Book.woff2') format('woff2'),
        url('ArbeitProTrial-Book.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
 
@font-face {
    font-family: 'Arbeit Pro Trial';
    src: url('ArbeitProTrial-Regular.woff2') format('woff2'),
        url('ArbeitProTrial-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arbeit Pro Trial';
    src: url('ArbeitProTrial-Bold.woff2') format('woff2'),
        url('ArbeitProTrial-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arbeit Pro Trial';
    src: url('ArbeitProTrial-Medium.woff2') format('woff2'),
        url('ArbeitProTrial-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arbeit Pro Trial';
    src: url('ArbeitProTrial-Light.woff2') format('woff2'),
        url('ArbeitProTrial-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arbeit Pro Trial Book';
    src: url('ArbeitProTrial-Semi-Bold.woff2') format('woff2'),
        url('ArbeitProTrial-Semi-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arbeit Pro Trial';
    src: url('ArbeitProTrial-Regular.woff2') format('woff2'),
        url('ArbeitProTrial-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src: url('ArialMT.woff2') format('woff2'),
        url('ArialMT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
