@import "../../../../assets/theme/_var.scss";

.activeAlertstable {
    .table {
        &.innerCommontable {
            tr {
                th {
                    @media (max-width:767px) {
                        &:first-child {
                            min-width: 12rem;
                        }
                    }
                }
            }

            tr {
                td {
                    @media (max-width:767px) {
                        &:first-child {
                            min-width: 12rem;
                        }
                    }

                    button {
                        border: 0;
                        background-color: transparent;
                        padding: 0;
                    }
                }
            }
        }
    }

    .table-responsive {
        max-height: 37.5rem;
        overflow-y: auto;

        @media (max-width:1149px) {
            max-height: 35.5rem;
            margin-top: 1.2rem;
        }

        @media (max-width:767px) {
            max-height: 35.5rem;
        }
    }
}