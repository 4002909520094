@import "./assets/theme/_var.scss";

:root {
  --animate-duration: 1s;
  --animate-delay: 0.2s;
  --animate-repeat: 1;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: $baseFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  color: $white;
  overflow-x: hidden;
  // background-color: $baseColor;
}


body.light-theme {
  color: $black;
}

.mainSitelayout {
  min-height: 100vh; 
}

.light-theme {
  .mainSitelayout {
    background-color: #F1F5F9;
    // @include theme() {
    //   background-color: theme-get("baseColor");
    // }
  }
}

.dark-theme {
  .mainSitelayout {
    background-color: $baseColor;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

hr {
  color: $white;
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-size: 1.4rem;
  }
}

a,
a:hover {
  text-decoration: none;
  transition: all ease-in-out 0.3s;
}

a,
span {
  display: inline-block;
}

img {
  max-width: 100%;
}

.buyBtn {
  width: 100%;
  max-width: 100px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ol {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

h3 {
  font-size: 2.4rem;

  @media (max-width:1399px) {
    font-size: 2.2rem;
  }

  @media (max-width:767px) {
    font-size: 2rem;
  }
}

h6,
p {
  font-size: 1.4rem;
}

.pt-40 {
  padding-top: 4rem;
}

.pt-50 {
  padding-top: 5rem;
}

.pt-70 {
  padding-top: 7rem;
}

.pb-40 {
  padding-bottom: 4rem;
}

.pb-50 {
  padding-bottom: 5rem;
}

.pb-70 {
  padding-bottom: 7rem;
}

.py-80 {
  padding: 8rem 0;

  @media (max-width: 767px) {
    padding: 4rem 0;
  }
}

.red_text {
  color: $redclr !important;
}

.green_text {
  color: $greentextclr !important;
}

.greenclr {
  color: $greenclr !important;
}

.yellow_text {
  color: $yellowclr !important;
}

.grey_text {
  color: $textclr2 !important;
}

.greenbg {
  background-color: $greenclr !important;
}

.redbg {
  background-color: $redclr !important;
}


body {
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $borderColor;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $scrollclr;
    border-radius: 1rem;
  }
}

.container {
  max-width: 1630px;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

label {
  color: $white;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: normal;
}

.from-control {
  height: 5rem;
  box-shadow: none;
  outline: none;
  width: 100%;
  padding: 0.8rem 2rem;
  border-radius: 1.2rem;
  border: 1px solid $borderColor;
  background-color: #374f58;
  color: $white;
  font-size: 1.6rem;

  @media (max-width: 1599px) {
    height: 4.5rem;
    font-size: 1.4rem;
    padding: 1rem 1.5rem;
  }

  &::placeholder {
    color: $white;
  }

  &:focus {
    box-shadow: none;
  }
}

// input:autofill,
// input:autofill:hover,
// input:autofill:focus,
// input:autofill:active {
//   transition: background-color 5000s ease-in-out 0s;
//   caret-color: transparent !important;
//   -moz-text-fill-color: $white;
// }

input:-webkit-autofill,
input:autofill {
  outline: none !important;
  border: none !important;
  background-image: none !important;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.btnTabs {
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-y: auto;
    display: flex;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      height: 0rem;
    }
  }

  &.nav {
    border: 0;
    background-color: $greybg;
    display: inline-flex;
    border-radius: 0.8rem;
    min-height: 3rem;

    @include theme() {
      background-color: theme-get("tabbg");
    }

    .nav-item {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }

      .nav-link {
        border-radius: 0.8rem;
        border: 1px solid transparent;
        background: transparent;
        display: flex;
        padding: 0.6rem 1.6rem;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: $textclr;

        @media (max-width: 767px) {
          // padding: 1rem 0;
          // font-size: 1.4rem;
        }

        &.active {
          border-color: $white;
          color: $white;

          @include theme() {
            border-color: theme-get("textcolor");
            color: theme-get("textcolor");
          }
        }
      }
    }
  }
}

.borderTabs {
  @media (max-width: 767px) {
    flex-wrap: nowrap;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 0rem;
    }
  }

  &.nav {
    border: 0;

    .nav-item {

      .nav-link {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $borderColor;
        background: transparent;
        display: flex;
        padding: 0.8rem 1.6rem;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: normal;
        color: $textclr1;
        font-family: $baseArialFont;

        @include theme() {
          border-color: theme-get("whiteborder01");
        }

        @media (max-width: 767px) {
          // padding: 1rem 0;
          font-size: 1.4rem;
        }

        &.active {
          border-color: $blueclr;
          color: $blueclr;

          svg {
            path {
              fill: $blueclr;
            }
          }
        }
      }
    }
  }
}

.dropdown {
  &-toggle {
    border: 0;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: normal;

    img {
      margin-right: 1rem;
    }

    &::after {
      margin-left: 1rem;
    }
  }

  &-menu {
    background: $greybg;
    padding: 0;
    border-radius: 1rem;

    @include theme() {
      background-color: theme-get("greybg");
    }

    a {
      color: $white;
      font-weight: 400;
      line-height: 2.2rem;
      padding: 0.5rem 1.2rem;
      display: flex;
      align-items: center;
      font-size: 1.2rem;

      @include theme() {
        color: theme-get("textcolor");
      }

      @media (max-width: 575px) {
        padding: 1rem 1.5rem;
        font-size: 1.2rem;
        line-height: 2rem;
      }

      &:first-child {
        border-radius: 0.8rem 0.8rem 0 0;
      }

      &:last-child {
        border-radius: 0 0 0.8rem 0.8rem;
      }

      span {
        margin-right: 2rem;

        @media (max-width: 575px) {
          margin-right: 1rem;
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;

          // path {
          //   @include theme() {
          //     stroke: theme-get("textcolor");
          //   }
          // }
        }
      }

      &:hover,
      &:focus {
        background-color: $blueclr;
        color: $white;

        span {
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
}

body .react-date,
body .react-time {
  &-picker {
    width: 100%;

    &__wrapper {
      border: 0;
    }

    &__inputGroup {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: normal;
      padding-left: 0;
      color: $white;

      @include theme() {
        color: theme-get("textcolor");
      }

      &__leadingZero {
        color: $textclr3;
      }

      &__divider,
      &__input {
        color: $textclr3;

        // @include theme() {
        //   color: theme-get("textcolor");
        // }
      }

      input {
        @include theme() {
          color: theme-get("labeltextclr");
        }

        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }

    &__button {
      padding: 0;

      svg {

        line,
        rect,
        path,
        circle {
          stroke: #8891A3;
        }
      }
    }

    &__clear-button {
      position: absolute;
      right: 3rem;
      bottom: 0rem;
      display: none;
    }

    &__calendar-button,
    &__clock-button {
      position: absolute;
      right: 0;
      top: -10px;
    }
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: $blueclr !important;
}

.commonCardbox {
  background-color: $cardbg;
  padding: 2.5rem;
  border-radius: 1rem;

  @include theme() {
    background-color: theme-get("cardbg");
  }

  @media (max-width:767px) {
    padding: 2rem 1.2rem;
  }
}

.backBtn {
  a {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    color: $white;

    @include theme() {
      color: theme-get("textcolor");
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #E9F0FD;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 4px;
      margin-right: 1.2rem;
    }
  }
}

.accordion {
  background-color: transparent;
  border: 0;
  border-radius: 0;

  .accordion-item {
    background-color: $baseColor;
    border: 1px solid $white20;
    margin-bottom: 2rem;
    border-radius: 10px;
    color: $white;

    @include theme() {
      background-color: theme-get("baseColor");
      color: theme-get("textcolor");
      border-color: theme-get("whiteborder01");
    }

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      .accordion-button {
        background-color: transparent;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: normal;
        color: $white;
        border: 0;
        box-shadow: none;
        padding: 2rem;

        @include theme() {
          color: theme-get("textcolor");
        }

        @media (max-width:767px) {
          padding: 1rem 1.5rem;
          font-size: 1.5rem;
        }

        &:focus {
          box-shadow: 0;
          outline: 0;
        }

        &::after {
          content: "";
          background-image: url("./assets/images/downarrow.svg");
          width: 3rem;
          height: 3rem;
          background-size: 3rem;

          @media (max-width:767px) {
            width: 2.5rem;
            height: 2.5rem;
            background-size: 2.5rem;
          }
        }

        &:not(.collapsed)::after {
          transform: rotate(-90deg);
        }
      }
    }

    .accordion-collapse {
      border: 0;

      .accordion-body {
        padding: 0 2rem 2rem;

        @media (max-width:767px) {
          padding: 0 1.5rem 2rem;
        }
      }
    }
  }
}

.form-control {
  background-color: $greybg;
  border: 0;
  border-radius: 0.8rem;
  color: $white;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  padding: 1rem 1.2rem;

  @include theme() {
    background-color: theme-get("greybg");
    color: theme-get("textcolor");
    // border-color: theme-get("whiteborder01");
  }

  &:focus {
    background-color: $greybg;
    outline: 0;
    box-shadow: none;
    color: $white ;

    @include theme() {
      background-color: theme-get("greybg");
    }
  }

  &:disabled {
    background-color: $greybg;
    border-radius: 0.8rem;
    cursor: not-allowed;

    @include theme() {
      background-color: theme-get("greybg");
    }
  }
}

.react-datetime-picker {
  width: 100%;

  &--disabled {
    background-color: transparent;
    border-radius: 0.8rem;
    cursor: not-allowed;
  }

  &__inputGroup {
    &__input {
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
  }

  &__wrapper {
    width: 100%;
    line-height: normal;
    padding: 0.6rem 1.2rem;
    border-radius: 0.8rem;
    border: 0;
    background-color: $greybg;
    position: relative;

    @include theme() {
      background-color: theme-get("greybg");
      color: theme-get("textcolor");
    }
  }

  &__calendar {
    width: 30rem;
    max-width: 100vw;

    @media (max-width: 767px) {
      width: 23rem;
    }

    .react-calendar {
      background-color: $greybg;
      border-color: $borderClr8891A3;
      color: $white;

      @include theme() {
        background-color: theme-get("greybg");
        color: theme-get("textcolor");
        border-color: theme-get("whiteborder01");
      }

      &__tile {
        color: $white;
        padding: 1.8rem 0.5rem !important;

        @include theme() {
          color: theme-get("textcolor");
        }

        &:hover,
        &--hasActive {
          background-color: $blueclr !important;
          color: $white;
        }

        &--now {
          background-color: $greenbg !important;
          color: $white;

          @include theme() {
            color: theme-get("whitebtn") !important;
          }

          &:enabled {
            &:focus {
              background-color: $greenbg !important;
              color: $white;

              @include theme() {
                color: theme-get("whitebtn") !important;
              }
            }
          }
        }
      }

      &__navigation {
        margin-bottom: 0.5rem;

        button {
          color: $white;

          @include theme() {
            color: theme-get("textcolor");
          }

          &:disabled {
            background-color: $greydarkbg !important;

            @include theme() {
              background-color: theme-get("whiteborder01") !important;
              color: theme-get("textcolor");
            }
          }

          &:enabled {

            &:hover,
            &:focus {
              background-color: $blueclr !important;
              color: $white;
            }
          }

          &:hover {
            background-color: $blueclr !important;
          }
        }
      }

      &__year-view {
        &__months {
          &__month {
            color: $white;

            @include theme() {
              color: theme-get("textcolor");
            }

            &:hover {
              background-color: $blueclr !important;

              @include theme() {
                color: theme-get("whitebtn");
              }
            }
          }
        }
      }

      &__month-view {
        &__weekdays {
          &__weekday {
            abbr[title] {
              text-decoration: none;
            }
          }
        }

        &__days {
          &__day {
            color: $white;
            padding: 0.8rem 0.5rem !important;

            @include theme() {
              color: theme-get("textcolor");
            }

            &:hover {
              background-color: $blueclr !important;

              @include theme() {
                color: theme-get("whitebtn");
              }
            }
          }
        }


      }

      &__tile {
        &:disabled {
          background-color: $greydarkbg !important;

          @include theme() {
            background-color: theme-get("whiteborder01") !important;
            color: theme-get("textcolor");
          }
        }
      }
    }
  }

  &__calendar-button,
  &__clear-button {
    svg {
      stroke: $white;

      @include theme() {
        stroke: theme-get("textcolor");
      }

      line {
        fill: $white;

        @include theme() {
          fill: theme-get("textcolor");
        }
      }
    }
  }

  &__clock {
    background-color: $greybg !important;
    border-color: $borderClr8891A3;

    @include theme() {
      background-color: theme-get("greybg") !important;
      border-color: theme-get("whiteborder01");
    }

    .react-clock {
      &__face {
        border-color: $white;

        @include theme() {
          border-color: theme-get("whiteborder01");
        }
      }

      &__mark,
      &__hand {
        &__body {
          background-color: $white;

          @include theme() {
            background-color: theme-get("textcolor");
          }
        }
      }
    }
  }
}