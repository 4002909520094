@import "../../assets/theme/_var.scss";

.userPortfolio {
    padding: 2rem 0;

    .portfolioBalance {
        h6 {
            color: $white;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: normal;

            @include theme() {
                color: theme-get("tabletext");
            }

            @media (max-width:767px) {
                font-size: 1.2rem;
            }
        }

        h3 {
            color: $white;
            font-size: 2.6rem;
            font-weight: 300;
            line-height: normal;

            @include theme() {
                color: theme-get("tabletext");
            }

            @media (max-width:767px) {
                font-size: 1.8rem;
            }
        }

        ul {
            li {
                button {
                    background-color: transparent;
                    border: 0;
                    color: $text989898;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: normal;
                    min-width: 3.5rem;
                    height: 2.3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10rem;

                    @include theme() {
                        color: theme-get("charttextclr");
                    }

                    &.active {
                        background-color: $blueclr;
                        color: $white;
                    }
                }
            }
        }
    }

    .assetAllocation {
        h5 {
            color: $white;
            font-size: 2.4rem;
            font-weight: 600;
            line-height: normal;
            padding: 2rem 2.2rem;
            border-bottom: 1px solid ;

            @include theme() {
                color: theme-get("tabletext");
                border-color: theme-get("whiteborder01");
            }

            @media (max-width:767px) {
                padding: 2rem 1rem;
                font-size: 1.8rem;
            }
        }

        .pieChart {
            padding: 2rem 2.2rem;
            // width: 95%;
            // margin: 0 auto;

            @media (max-width:767px) {
                padding: 2rem 1.2rem;
            }
        }
    }

    .dashHeading {
        h3 {
            color: $white;
            font-size: 2.4rem;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;

            @include theme() {
                color: theme-get("tabletext");
            }

            @media (max-width:767px) {
                font-size: 1.8rem;
            }
        }
    }

    .totalWallet {
        margin-top: 2rem;
    }
}

.walletSearch {
    &.commonSearch {
        max-width: 44.5rem;
        width: 100%;

        @media (max-width:767px) {
            max-width: 35rem;
        }

        @media (max-width:575px) {
            max-width: 100%;
        }

        .searchInput {
            svg {
                width: 1.8rem;
                height: 1.8rem;
            }

            input {
                min-height: 4rem;
                font-size: 1.4rem;
                padding-left: 4rem;
                border-radius: 0.6rem;

                &::placeholder {
                    color: $white;
                    opacity: 1;
                }
            }
        }
    }
}

.portfolioSinglewallet {
    .backBtn {
        margin-bottom: 1.8rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $white50;
    }

    .dashHeading {
        h3 {
            display: flex;
            align-items: center;

            @include theme() {
                color: theme-get("textcolor");
              }

            @media (max-width:767px) {
                display: block;
            }

            span {
                color: $white50;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                @include theme() {
                    color: theme-get("lighttextclr");
                  }

                @media (max-width:767px) {
                    padding-top: 1rem;
                }
            }
        }
    }

    .commonSearch {
        margin: 2.5rem 0 3rem;
    }

    // .singleWalletTable {
    //     .table-responsive {
    //         height: 53rem;
    //         overflow-y: auto;
    //     }
    // }
}

.atsPiechart {
    height: 400px;
    >div {
        >div {

            >div {
                display: flex;

                .am5-layer-0 {
                    position: static !important;
                }

                // .am5-layer-30 {
                //     // position: static !important;
                //     // width: 500px !important;
                //     // margin: 0 auto;
                // }
            }
        }
    }
}