@import '../../../assets/theme/_var.scss';

.loginPage {
    padding: 5rem 0 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $greydarkbg;

    @media (max-width:1599px) {
        padding: 5rem 0 3rem;
    }

    @media (max-width:991px) {
        padding: 3rem 0 2rem;
    }

    .LoginLogo {
        padding-bottom: 6rem;

        @media (max-width:1599px) {
            padding-bottom: 5rem;
        }

        @media (max-width:1399px) {
            padding-bottom: 3rem;
        }

        img {
            width: 19rem;
        }
    }

    .container {
        max-width: 103.5rem;
    }

    .colLogin {
        width: 53.9rem;
        margin: 0 auto;
    }

    &_boxContent {
        padding: 6rem 7rem 6rem;
        background-color: $baseColor;
        border-radius: 0 1.2rem 1.2rem 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width:1599px) {
            padding: 5rem 4rem;
        }

        @media (max-width:767px) {
            padding: 3rem 1.5rem;
        }

        .loginHeading {
            padding-bottom: 6.4rem;

            @media (max-width:767px) {
                padding-bottom: 3rem;
            }

            h1 {
                font-family: $baseArialFont;
                font-size: 2.4rem;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                margin-bottom: 0.5rem;
            }

            p {
                font-size: 1.6rem;
                font-weight: 400;
                line-height: normal;
                color: $textclr3;
            }
        }

        .customInput {
            background-color: transparent !important;
            margin-bottom: 2.4rem;
            border: 0;
            padding: 0;

            @media (max-width:767px) {
                margin-bottom: 1.5rem;
            }

            label {
                color: $white;
                font-size: 1.2rem;
            }

            &_inner {
                position: relative;

                .form-control {
                    color: $black !important;
                    background-color: $white !important;
                    border: 1px solid $inputborder;
                    line-height: normal;
                    padding: 0.5rem 1.2rem;
                    border-radius: 0.8rem;
                    margin-top: 0.5rem;
                    min-height: 4.5rem;
                    font-size: 1.3rem;

                    &::placeholder {
                        color: $blackplaceholderclr !important;
                    }

                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus,
                    &:-webkit-autofill:active {
                        transition: background-color 5000s ease-in-out 0s;
                        caret-color: transparent !important;
                        -webkit-text-fill-color: $black;
                    }
                }
            }
        }

        .loginBtn {
            padding-top: 5rem;

            @media (max-width:1599px) {
                padding-top: 4rem;
            }

            @media (max-width:1399px) {
                padding-top: 3rem;
            }

            .btn-style {
                height: 4.8rem;
            }
        }
    }

    .anAccount {
        color: #666;
        font-family: $baseArialFont;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: normal;
        margin-top: 2.4rem;

        @media (max-width:767px) {
            margin-top: 1.5rem;
        }

        a {
            color: $blueclr;
            font-weight: 600;
        }
    }

    .loginFooter {
        margin-top: 5rem;
        padding: 0 2rem;

        @media (max-width:1599px) {
            margin-top: 3rem;
        }

        p {
            color: #696F79;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }
    }


}